<template>

    <div class="invoice" id="div1" style="display: block">
      <div style="height: 12px;"></div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          offset="10"
          @load="onLoad"
      >
        <van-cell-group  v-for="item in list" :key="item.id" class="invoice-list">
          <van-cell :to="'/contract/detail?id='+item.id">
            <van-row>
              <van-col span="16" class="invoice-title">合同编号：{{ item.contract_no }}</van-col>
              <van-col span="4" style="text-align: right" v-if="item.is_effect !== 3">
                <van-tag plain type="primary" style="opacity:0.6;" v-if="item.is_end===1">已结清</van-tag>
                <van-tag plain type="warning" v-if="item.is_end===2">未结清</van-tag>
              </van-col>
              <van-col span="4" style="text-align: right" v-if="item.is_effect===3">

              </van-col>
              <van-col span="4" style="text-align: right">
                <van-tag plain type="success" v-if="item.is_effect===1">生效中</van-tag>
                <van-tag plain color="#aaaaaa" v-if="item.is_effect===2">已过期</van-tag>
                <van-tag plain color="#aaaaaa" v-if="item.is_effect===3">已作废</van-tag>
              </van-col>
            </van-row>
          </van-cell>
          <van-cell class="invoice-detail" :to="'/contract/detail?id='+item.id">
            <van-col span="18" class="invoice-desc">
              <span class="font-color">类型：</span>
              {{item.type_text}}
            </van-col>
            <van-col span="24" class="invoice-desc">
              <van-row>
                <van-col :span="12" class="invoice-desc">
                  <span class="font-color">总金额：</span>
                  {{item.total_amount}}
                </van-col>
                <van-col :span="12" class="invoice-desc">
                  <span class="font-color">已支付：</span>
                  {{item.pay_total_amount}}
                </van-col>
              </van-row>
            </van-col>
            <van-col span="18" class="invoice-desc">
              <span class="font-color">有效期：</span>
              {{item.use_time}}
            </van-col>
          </van-cell>
          <van-row v-if="item.is_end===2 && item.cycle_total_amount > 0 && item.is_show==2 && item.is_end_pay==1">
            <van-col span="1">
            </van-col>
            <van-col span="22" style="height: 32px;font-size: 13px;line-height: 32px;background-color:#FAFAFA;margin-bottom: 10px;text-align: left;border-radius: 2px;">
              <span class="notice-font-color" style="padding-left: 7px">{{item.cycle_time}}前需支付下期费用：{{item.cycle_total_amount}}</span>
            </van-col>
            <van-col span="1">
            </van-col>
          </van-row>
          <van-row v-if="item.is_effect===1 && item.expire_type==2">
            <van-col span="1">
            </van-col>
            <van-col span="22" style="height: 32px;font-size: 13px;line-height: 32px;background-color:#FAFAFA;margin-bottom: 10px;text-align: left;border-radius: 2px;">
              <span class="notice-font-color" style="padding-left: 7px">本合同将于{{item.end_time}}到期。</span>
            </van-col>
            <van-col span="1">
            </van-col>
          </van-row>
        </van-cell-group>
      </van-list>
      </van-pull-refresh>
    </div>
    <!-- nodata -->
    <div class="no-data" id="div2" style="display: none">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list >
        <van-empty description="暂无数据" class="invoice-empty">
<!--          <van-button style="border-radius: 4px;" type="danger" class="bottom-button" size="normal" @click="addBtn"></van-button>-->
        </van-empty>
      </van-list>
      </van-pull-refresh>
    </div>
<!--    <div class="invoice-list-group-logout">-->
<!--&lt;!&ndash;      <van-button round color="#CCCCCC" size="normal" :to="{path:'/user/index'}">账号</van-button>&ndash;&gt;-->
<!--      <van-button round color="#CCCCCC" size="normal" :to="'/user/index?type='+2">账号</van-button>-->
<!--    </div>-->
  <van-tabbar v-model="active">
    <van-tabbar-item
        v-for="(item,index) in tabbars"
        :key="index"
        @click="tab(index,item.name)"
    >
      <span :class="currIndex == index ? active:''">{{item.title}}</span>
      <template #icon="props">
        <img :src="props.active ? item.active : item.normal">
      </template>
    </van-tabbar-item>
  </van-tabbar>

</template>

<script>
import {reactive,  toRefs} from "vue";
import {Contract} from "@/api/contract";
import {getMerchantDetail} from "@/api/merchant";

export default {
  setup() {
    const state = reactive({
      receive_list: [],
      list: [],
      loading: false,
      finished: false,
      // show_receive: false,
      page: 1,
      page_size: 10,
      total:0,
      isLoading: false,
      add_btn:false,
      value1: 2,
      value2: 20,
      time_type:2,
      status:20,
      invoice_id:'',
      invoice_time:'',
      invoice_no:'',
      tax_no:'',
    });
    const loadData = ()=> {
      var div1= document.getElementById("div1");
      var div2= document.getElementById("div2");
      Contract({
        type:'page',
        page:state.page,
        page_size:state.page_size,
      }).then(function (res) {
        if (res.Code == 200) {
          const resp = res.Data;
              // console.log(resp);
          if (resp.data.length === 0) {  		// 判断获取数据条数若等于0
            state.finished = true;		// 停止加载
            if(state.page ===1 && resp.data.length ===0){
              state.list = [];				// 清空数组
            }
          }else {
            // 若数据条数不等于0
            state.total = resp.total;		// 给数据条数赋值
            state.list.push(...resp.data)	// 将数据放入list中
            state.loading = false;			// 加载状态结束
            // 如果list长度大于等于总数据条数,数据全部加载完成
            if (state.list.length >= state.total) {
              state.finished = true;		// 结束加载状态
            }
          }

          if(state.list.length === 0){
            div1.style.display='none';
            div2.style.display='block';
          }else{
            div1.style.display='block';
            div2.style.display='none';
          }
          state.page++;
          var arr = [1,2];
          if(state.list.length>0 && arr.indexOf(state.list[0].status)>-1){
            state.add_btn = true;
          }
        }
      }).catch(function (err) {
          console.info(err)
      })
    }
    const onLoad = () => {
      loadData();
    };
    const onRefresh = ()=> {
      setTimeout(() => {
        state.isLoading = false;
        state.page=1;
        state.list = [];
        // 将 loading 设置为 true，表示处于加载状态
        state.loading = true;
        // 清空列表数据
        state.finished = false;
        state.add_btn = false;
        loadData();
      }, 1000);
    };
    return {
      ...toRefs(state),
      onLoad,
      onRefresh,
    };
  },
  created() {
    let open_id = this.$route.query.open_id;
    // localStorage.removeItem('open_id');
    if(open_id){
      localStorage.setItem('open_id',open_id)
    }
    // 刷新 merchant info
    getMerchantDetail().then(function (res){
      if(res.Code===200){
        const info = res.Data;
        //持久化
        localStorage.setItem('user',JSON.stringify(info))
      }
    }).catch(function (err){
      console.info(err)
    })
  },
  mounted() {
    let open_id = localStorage.getItem('open_id');
    let user = JSON.parse(localStorage.getItem('user'));
    if(!open_id){
      window.location.href = '/wechat/user?state='+user.id;
    }
  }
};
</script>

<style lang="less" scoped>
.van-tag{
  line-height: 20px;
}
.van-dropdown-menu>.van-dropdown-menu__item{
  border: #bdb9b1 1px solid;
}
.van-button--danger{
  background-color: #ff552e;
}
.no-data{
  background: #f6f6f6;
  :deep(.invoice-empty){
    height: auto;
    min-height: 100vh;
    background: #fff;
  }
}
.invoice {
  height: auto;
  min-height: 100vh;
  background: #f6f6f6;
  padding-bottom: 60px;
  :deep(&-list){
    margin-bottom: 10px;
  }
  :deep(&-title){
    height: 25px;
    font-size: 13px;
    font-weight: 400;
    color: #333333;
  }
  :deep(&-desc){
    height: 25px;
    font-size: 13px;
    font-weight: 400;
    color: #333333;
  }
  :deep(.font-color){
    color:#999999;
  }
  :deep(.notice-font-color){
    color:#666666;
  }
}
.bottom-button {
  width: 160px;
  height: 40px;
}
.invoice-list-group{
  right: 22px;
  bottom: 20%;
  position: fixed;
  width: 50px;
  height: 50px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  border-radius: 30px;
  cursor: pointer;
}
.invoice-list-group>button{
  height: 49px;
  width: 49px;
  border-radius: 50%;
}
//退出
.invoice-list-group-logout{
  right: 22px;
  bottom: 10%;
  position: fixed;
  width: 50px;
  height: 50px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  border-radius: 30px;
  cursor: pointer;
}
.invoice-list-group-logout>button{
  height: 49px;
  width: 49px;
  font-size: 12px;
  border-radius: 50%;
}
.invoice-list-group-logout,.invoice-list-group{
  :deep(.van-button__content) {
    word-break: keep-all;
    white-space: nowrap;
  }
}

.van-row{
  width: 100%;
}
.van-dropdown-menu{
  :deep(.van-dropdown-menu__title) {
    font-size: 0.37333rem;
  }
}
</style>

import request from '../utils/request'

const Api = {
    Contract: 'contract/list',
    ContractDetail: 'contract/detail',
    ContractInfo: 'contract/info',
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function Contract (data) {
    return request({
        url: Api.Contract,
        method: 'POST',
        params:data
    })
}


/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function ContractDetail (data) {
    return request({
        url: Api.ContractDetail,
        method: 'POST',
        data:data
    })
}


/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getContractInfo (data) {
    return request({
        url: Api.ContractInfo,
        method: 'GET',
        data:data
    })
}
